import React from 'react';
import loadable from '@loadable/component';

const HeaderNavMobile = loadable(
    () =>
        import(
            /* webpackChunkName: "HeaderNavMobile.loadable" */ 'app/shared/modules/navigation/header/HeaderNavMobileComponent'
        )
);

interface Props {
    onCloseHamburgerNav: () => void;
    onOpenHamburgerNav: () => void;
    unreadMsgCount?: number;
}

const LoadableHeaderNavMobile = (props: Props) => {
    return <HeaderNavMobile {...props} />;
};

export default LoadableHeaderNavMobile;
