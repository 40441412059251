// @ts-nocheck
/* eslint-enable */
import isEmpty from 'lodash/isEmpty';

import api from 'app/shared/utils/api';
import constants from 'app/shared/constants/ConstantsBundle';
import Conversation from 'app/shared/models/Conversation';

const ConversationActions = {
    fetchMessageCount() {
        return (dispatch, getState) => {
            const store = getState();
            const { user = {} } = store;
            const { loggedIn } = user;

            if (!loggedIn) {
                return Promise.resolve();
            }

            return dispatch(api.listing.conversation.messageCounts()).then((apiResponse = {}) => {
                const { data = {} } = apiResponse;
                const { unreadCount } = data;

                return dispatch({
                    type: constants.UPDATE_UNREAD_CONVERSATION_COUNT,
                    payload: unreadCount
                });
            });
        };
    },

    /**
     * Optimistically add sent message details for Rachel Satellite to user.conversations reducer
     * so we can immediately display feedback that shows the user sent a message,
     * rather than waiting for Conversation Service to parse / update message, which can take
     * a few seconds.
     */
    optimisticSetSentMessage(aliasEncoded) {
        if (!aliasEncoded) {
            return Promise.resolve();
        }

        return (dispatch) => {
            const conversation = {
                lastSender: 'user',
                id: '',
                lastMessageTimestamp: Date.now(),
                isUnread: false,
                satelliteEnabled: true
            };

            // Call conversation service after a successful submit in order to update
            // HDP with correct conversation link id + url. Because recent messages aren't immediately
            // available, we first optimistically set a sent message status to display on HDP,
            // and then wait a few seconds before calling the backend API.
            setTimeout(() => {
                dispatch(ConversationActions.fetchRecentMessages({ aliasEncoded }));
            }, 5000);

            return dispatch({
                type: constants.UPDATE_LISTING_CONVERSATION,
                payload: {
                    aliasEncoded,
                    conversation
                }
            });
        };
    },

    fetchRecentMessages({ aliasEncoded }) {
        return (dispatch, getState) => {
            const store = getState();
            const { user = {} } = store;
            const { loggedIn } = user;

            if ((!loggedIn) || !aliasEncoded) {
                return Promise.resolve();
            }

            return dispatch(api.listing.conversation.recentMessages(aliasEncoded))
                .then((apiResponse = {}) => {
                    // If conversation exists, the API will return a response with a data object.
                    // If no conversation exists between the user and tenant, no data will be
                    // returned from the API.
                    const { data = {} } = apiResponse;
                    const { conversations = [] } = data;
                    if (!isEmpty(conversations)) {
                        // Conversations from backend API are an array sorted in reverse chronological order.
                        const [mostRecentMessage] = conversations;
                        const conversation = Conversation.create(mostRecentMessage);

                        return dispatch({
                            type: constants.UPDATE_LISTING_CONVERSATION,
                            payload: {
                                aliasEncoded,
                                conversation
                            }
                        });
                    }
                });
        };
    }
};

export default ConversationActions;
